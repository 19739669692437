<script setup>
    import { state } from '../store/store.js';
</script>

<script>
	export default {
		name: 'ViewAsCustomer',
		async mounted() {
            await this.setAdminSession();
		},
		data: () => ({
		}),
		methods: {
            async setAdminSession() {
                let adminUser = await state.api.call("getAdminSessionIfExists");
                if (Object.keys(adminUser).length > 0) {
                    state.admin = adminUser;
                }
            },
            async resetUser() {
                if (state.admin.rid) {
                    let sess = await state.api.call("setUserSession", {
                        adminid: 0,
                        uid: state.admin.rid,
                        email: state.admin.emailBusiness,
                        fname: state.admin.firstName,
                        lname: state.admin.lastName
                    });
                    this.$router.push({ name: 'MyAccount' });
                }else {
                    state.flash({
                        type: 'error',
                        message: "No admin user available to reset to. Refresh the page and try again."
                    });
                }
            }
		},
	};
</script>

<template>
	<aside class="_viewascustomer" v-if="state.admin.rid">
        <span>You are currently logged in as <strong>{{ state.user.firstName }} {{ state.user.lastName }}</strong>.</span>
        <button @click="resetUser">Change Back to Admin Account</button>
    </aside>
</template>

<style scoped>
	._viewascustomer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 0.5rem 1rem;
        background-color: var(--color-red);
        color: #fff;

        span {
            margin-right: 1rem;
        }

        button {
            background-color: #fff;
            color: var(--color-red);
            border: 1px solid #fff;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            cursor: pointer;
            font-weight: bold;
            text-transform: uppercase;
            transition: box-shadow 250ms ease-in-out;

            &:hover,
            &:focus {
                box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
            }
        }
    }
</style>
